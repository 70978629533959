@import url('https://fonts.googleapis.com/css2?family=Kameron:wght@400;700&display=swap');

/* Shared styles for About, Privacy, Terms pages */
.content-page-container {
    max-width: 700px; 
    margin: 20px auto 20px;
    padding: 1px;
    font-family: 'Inter', sans-serif;
    line-height: 1.5;
    color: var(--text-color1);
    background-color: var(--cardBackground-color);
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.content-page-header {
    text-align: center;
    margin-bottom: 1px;
}

.content-page-header h1 {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 0px;
    font-family: 'Kameron', sans-serif;
    font-weight: 200;
    font-size: 5em;
    margin-bottom: 1px;
    margin-top: 20px;
}

/* Specific styles for About page title if needed */
.about-title {
    /* Keep specific styles if necessary */
}
.about-subtitle {
    font-size: 0.5em;
}


.content-page-content {
    max-width: 600px; 
    margin: 0 auto;
    padding: 0 20px 20px; /* Add padding for content */
}

.content-page-section {
    margin-bottom: 20px;
}

.content-page-section h2 {
    font-size: 1.8em;
    margin-bottom: 15px;
    font-family: 'Kameron', sans-serif;
}

.content-page-section h3 {
    font-size: 1.4em;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: 'Kameron', sans-serif;
}

.content-page-section p, 
.content-page-section ul {
    margin-bottom: 15px;
}

.content-page-section ul {
    padding-left: 20px;
}

.content-page-section li {
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .content-page-container {
        margin: 20px auto auto auto;
        padding: 10px;
        padding-top: 10px;
    }

    .content-page-header h1 {
        font-size: 3em;
    }

    .content-page-section h2 {
        font-size: 1.5em;
    }

     .content-page-section h3 {
        font-size: 1.2em;
    }

    .content-page-content {
        padding: 0 15px 15px;
    }
} 